import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Toolbar,
  IconButton,
  CardHeader,
  CardActions,
  Button,
  TextField,
  Modal,
  Autocomplete,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import LayoutComponent from "../LayoutComponent";
import { customFetch } from "../../utils/apiUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShoppingCart, ArrowUpward, CheckCircle } from "@mui/icons-material";

const VendorDetails = ({
  setLoading,
}: {
  setLoading: (isLoading: boolean) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [vendor, setVendor] = useState<any>(null);
  const [vendorItems, setVendorItems] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [purchaseHistory, setPurchaseHistory] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchLoad, setSearchLoad] = React.useState(false);
  const [formData, setFormData] = useState({
    item_id: "",
    description: "",
    cost_per_unit: "",
    discount_details: "",
    unit_type: "pcs",
    url: "",
  });
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const selectedCompany = localStorage.getItem("selectedCompany");

  const fetchVendorDetails = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}`;
    setLoading(true);
    try {
      const response = await customFetch(url, { method: "GET" });
      const data = await response.json();
      setVendor(data);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
      toast.error("Error fetching vendor details");
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]);

  const fetchVendorItems = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}/items`;
    setLoading(true);
    try {
      const response = await customFetch(url, { method: "GET" });
      const data = await response.json();
      setVendorItems(data);
    } catch (error) {
      console.error("Error fetching vendorItems:", error);
      toast.error("Error fetching vendorItems");
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]);

  const handleSearch = async (query) => {
    if (!query) return;
    const url = `${process.env.REACT_APP_API_BASE_URL}/items`;
    const params = {
      company_id: parseInt(selectedCompany),
      search_term: query,
      material_type: "raw_material",
      page: 1,
      per_page: 10,
    };
    const queryString = Object.keys(params)
      .map((key) => key + "=" + encodeURIComponent(params[key]))
      .join("&");
    const apiUrl = url + "?" + queryString;
    setSearchLoad(true);

    try {
      const response = await customFetch(apiUrl, { method: "GET" });
      const data = await response.json();
      setItems(data.data);
    } catch (error) {
      console.error("Failed to fetch items:", error);
    } finally {
      setSearchLoad(false);
    }
  };

  /* const fetchPurchaseHistory = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}/purchase-history`;
    setLoading(true);
    try {
      const response = await customFetch(url, { method: "GET" });
      const data = await response.json();
      setPurchaseHistory(data);
    } catch (error) {
      console.error("Error fetching purchase history:", error);
      toast.error("Error fetching purchase history");
    } finally {
      setLoading(false);
    }
  }, [id, setLoading]); */

  const fetchPurchaseHistory = useCallback(async () => {
    const dummyData = [
      {
        id: 1,
        poNumber: "PO001",
        finalPOValue: 8000,
        poDate: "September 14, 2024",
        items: [
          {
            id: 3,
            name: "Item 1",
            poRaisedQty: 10,
            receivedQty: 8,
          },
          {
            id: 4,
            name: "Item 2",
            poRaisedQty: 20,
            receivedQty: 20,
          },
        ],
      },
      {
        id: 2,
        poNumber: "PO002",
        finalPOValue: 6000,
        poDate: "September 24, 2024",
        items: [
          {
            id: 5,
            name: "Item 3",
            poRaisedQty: 15,
            receivedQty: 15,
          },
          {
            id: 6,
            name: "Item 4",
            poRaisedQty: 12,
            receivedQty: 10,
          },
        ],
      },
    ];

    setLoading(true);
    try {
      // Simulate a delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setPurchaseHistory(dummyData);
    } catch (error) {
      console.error("Error fetching purchase history:", error);
      toast.error("Error fetching purchase history");
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  useEffect(() => {
    if (id) {
      fetchVendorDetails();
      fetchVendorItems();
      fetchPurchaseHistory();
    }
  }, [id, fetchVendorDetails, fetchVendorItems, fetchPurchaseHistory]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddClick = () => {
    setEditingItemId(null);
    setFormData({
      item_id: "",
      description: "",
      cost_per_unit: "",
      discount_details: "",
      unit_type: "pcs",
      url: "",
    });
    setIsModalOpen(true);
  };

  const handleEditClick = (item: any) => {
    setLoading(true);
    setEditingItemId(item.id);
    handleSearch(item.name);
    setTimeout(() => {
      setFormData({
        item_id: item.id,
        description: item.pivot.description,
        cost_per_unit: item.pivot.cost_per_unit,
        discount_details: item.pivot.discount_details,
        unit_type: item.pivot.unit_type,
        url: item.pivot.url,
      });
      setIsModalOpen(true);
      setLoading(false);
    }, 1000);
  };

  const handleDeleteClick = async (itemId: string) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}/items/${itemId}`;
    setLoading(true);
    try {
      await customFetch(url, { method: "DELETE" });
      toast.success("Item deleted successfully");
      setItems((prev) => prev.filter((item) => item.id !== itemId));
    } catch (error) {
      toast.error("Error deleting item");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const url = editingItemId
      ? `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}/items/${editingItemId}`
      : `${process.env.REACT_APP_API_BASE_URL}/vendors/${id}/items`;

    const method = editingItemId ? "PUT" : "POST";

    setLoading(true);
    try {
      await customFetch(url, {
        method,
        body: JSON.stringify(formData),
      });
      toast.success(
        editingItemId ? "Item updated successfully" : "Item added successfully"
      );
      setIsModalOpen(false);
      fetchVendorItems();
    } catch (error) {
      toast.error("Error saving item. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutComponent pageName="Vendor Details" setLoading={setLoading}>
      <Toolbar className="sticky top-0 z-10 border-b-2 pb-2 !min-h-10">
        <IconButton onClick={() => navigate("/vendors")}>
          <ArrowBackIcon />
        </IconButton>
      </Toolbar>
      <Box className="p-4">
        <Typography variant="h5" className="!mb-4">
          Vendor Details
        </Typography>
        {vendor ? (
          <Card className="mb-4 shadow-md">
            <CardContent>
              <Typography variant="body1">
                <strong>Name:</strong> {vendor.name}
              </Typography>
              <Typography variant="body1">
                <strong>Contact Person:</strong> {vendor.contact_person}
              </Typography>
              <Typography variant="body1">
                <strong>Contact Email:</strong> {vendor.contact_email}
              </Typography>
              <Typography variant="body1">
                <strong>Phone:</strong> {vendor.phone}
              </Typography>
              <Typography variant="body1">
                <strong>Address:</strong> {vendor.address}
              </Typography>
              <Typography variant="body1">
                <strong>GST:</strong> {vendor.gst_number}
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            className="my-8"
          >
            Loading vendor details...
          </Typography>
        )}
        <Box className="mb-4">
          <Typography
            variant="h5"
            className="!mb-4 flex items-center justify-between"
          >
            Item Details
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddClick}
            >
              Add Item
            </Button>
          </Typography>
          <Grid container spacing={2}>
            {vendorItems.length > 0 ? (
              vendorItems.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Card className="shadow-md">
                    <CardContent>
                      <Typography variant="h6" className="font-bold">
                        {item.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {item.description}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Notes:</strong> {item.pivot.description}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Cost:</strong> ₹ {item.pivot.cost_per_unit}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Discount Details:</strong>
                        {item.pivot.discount_details}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Type of Unit:</strong> {item.pivot.unit_type}
                      </Typography>
                    </CardContent>
                    <Box className="flex justify-end p-2">
                      <IconButton onClick={() => handleEditClick(item)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="textSecondary">
                No items associated with this vendor.
              </Typography>
            )}
          </Grid>
        </Box>
        <Box>
          <Typography variant="h5" className="!mb-4">
            History
          </Typography>
          <Grid container spacing={2}>
            {purchaseHistory.length > 0 ? (
              purchaseHistory.map((purchase) => (
                <Grid item xs={12} sm={6} md={3} key={purchase.id}>
                  <Card className="shadow-md">
                    <CardHeader
                      title={purchase.poNumber}
                      subheader={purchase.poDate}
                    />
                    <CardContent>
                      {purchase.items.map((item) => (
                        <div key={item.id} className="flex items-center mb-4">
                          <Typography variant="body2" color="textSecondary">
                            <ShoppingCart fontSize="small" /> {item.name} &nbsp;
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <ArrowUpward fontSize="small" /> {item.poRaisedQty}{" "}
                            &nbsp;
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            <CheckCircle fontSize="small" /> {item.receivedQty}
                          </Typography>
                        </div>
                      ))}
                    </CardContent>
                    <CardActions>
                      <Typography variant="h6">
                        &#x20b9;&nbsp;{purchase.finalPOValue}
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="textSecondary">
                No purchase history available.
              </Typography>
            )}
          </Grid>
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box className="bg-white p-6 rounded shadow-md max-w-md mx-auto mt-24">
          <Typography variant="h6" className="!mb-4">
            {editingItemId ? "Edit Item" : "Add Item"}
          </Typography>
          <input type="hidden" value={id} name="vendorId" />
          <Autocomplete
            options={items}
            getOptionLabel={(option) => option.name || ""}
            value={
              items.find((option) => option.id === formData.item_id) || null
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => {
              setFormData((prev) => ({
                ...prev,
                item_id: value ? value.id : null,
              }));
            }}
            onInputChange={(event, inputValue) => {
              handleSearch(inputValue);
            }}
            filterOptions={(x) => x}
            loading={searchLoad}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Item"
                className="!mb-4"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searchLoad ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={3}
            className="!mb-4"
          />
          <TextField
            label="Cost Per Unit"
            name="cost_per_unit"
            type="number"
            value={formData.cost_per_unit}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*\.?\d{0,2}$/.test(value)) {
                setFormData((prev) => ({ ...prev, cost_per_unit: value }));
              }
            }}
            fullWidth
            className="!mb-4"
          />
          <TextField
            label="Discount Details"
            name="discount_details"
            value={formData.discount_details || ""}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={2}
            className="!mb-4"
          />
          <TextField
            select
            label="Unit Type"
            name="unit_type"
            value={formData.unit_type || ""}
            onChange={handleInputChange}
            fullWidth
            className="!mb-4"
          >
            <MenuItem value="pcs">Pieces</MenuItem>
            <MenuItem value="sets">Sets</MenuItem>
          </TextField>
          <TextField
            label="URL"
            name="url"
            type="url"
            value={formData.url || ""}
            onChange={handleInputChange}
            fullWidth
            className="!mb-4"
            placeholder="https://example.com"
          />
          <Button variant="contained" onClick={handleSave} fullWidth>
            Save
          </Button>
        </Box>
      </Modal>
    </LayoutComponent>
  );
};

export default VendorDetails;
